// GENERAL
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SELECT_LANGUAGE_REQUEST = 'SELECT_LANGUAGE_REQUEST';
export const SELECT_LANGUAGE_SUCCESS = 'SELECT_LANGUAGE_SUCCESS';
export const SELECT_LANGUAGE_FAILURE = 'SELECT_LANGUAGE_FAILURE';
export const GET_COUNTRY_STATS = 'GET_COUNTRY_STATS';
export const GET_COUNTRY_STATS_SUCCESS = 'GET_COUNTRY_STATS_SUCCESS';
export const SELECT_COUNTRY_REQUEST = 'SELECT_COUNTRY_REQUEST';
export const SELECT_COUNTRY_SUCCESS = 'SELECT_COUNTRY_SUCCESS';
export const SELECT_COUNTRY_FAILURE = 'SELECT_COUNTRY_FAILURE';

// DILOGS
export const TOGGLE_REGISTER_ASSET_DIALOG = 'TOGGLE_REGISTER_ASSET_DIALOG';
export const TOGGLE_REGISTER_ORGANIZATION_DIALOG = 'TOGGLE_REGISTER_ORGANIZATION_DIALOG';
export const TOGGLE_REGISTER_PROJECT_DIALOG = "TOGGLE_REGISTER_PROJECT_DIALOG";
export const TOGGLE_EDIT_ASSET_DIALOG = 'TOGGLE_EDIT_ASSET_DIALOG';
export const TOGGLE_EDIT_ORGANIZATION_DIALOG = 'TOGGLE_EDIT_ORGANIZATION_DIALOG';
export const TOGGLE_EDIT_PROJECT_DIALOG = 'TOGGLE_EDIT_PROJECT_DIALOG';
export const TOGGLE_LOG_BACK_IN_DIALOG = 'TOGGLE_LOG_BACK_IN_DIALOG';

// AUTHENTICATION
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

export const DELETE_USER_FILE_REQUEST = 'DELETE_USER_FILE_REQUEST';
export const DELETE_USER_FILE_SUCCESS = 'DELETE_USER_FILE_SUCCESS';
export const DELETE_USER_FILE_FAILURE = 'DELETE_USER_FILE_FAILURE';

export const UPLOAD_AVATAR_OF_USER_REQUEST = 'UPLOAD_AVATAR_OF_USER_REQUEST';
export const UPLOAD_AVATAR_OF_USER_SUCCESS = 'UPLOAD_AVATAR_OF_USER_SUCCESS';
export const UPLOAD_AVATAR_OF_USER_FAILURE = 'UPLOAD_AVATAR_OF_USER_FAILURE';

export const GET_PUBLIC_USERDATA_REQUEST = 'GET_PUBLIC_USERDATA_REQUEST';
export const GET_PUBLIC_USERDATA_SUCCESS = 'GET_PUBLIC_USERDATA_SUCCESS';
export const GET_PUBLIC_USERDATA_FAILURE = 'GET_PUBLIC_USERDATA_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASS_RESET_STATE = 'FORGOT_PASS_RESET_STATE';

export const TOKEN_VERIFICATION_REQUEST = 'TOKEN_VERIFICATION_REQUEST';
export const TOKEN_VERIFICATION_SUCCESS = 'TOKEN_VERIFICATION_SUCCESS';
export const TOKEN_VERIFICATION_FAILURE = 'TOKEN_VERIFICATION_FAILURE';

export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAILURE = 'RESTORE_PASSWORD_FAILURE';

export const GET_MY_USERDATA_REQUEST = 'GET_MY_USERDATA_REQUEST';
export const GET_MY_USERDATA_SUCCESS = 'GET_MY_USERDATA_SUCCESS';
export const GET_MY_USERDATA_FAILURE = 'GET_MY_USERDATA_FAILURE';

export const CONFIRM_USER = 'CONFIRM_USER';

// USERS
export const GET_ALLUSERS_REQUEST = 'GET_ALLUSERS_REQUEST';
export const GET_ALLUSERS_SUCCESS = 'GET_ALLUSERS_SUCCESS';
export const GET_ALLUSERS_FAILURE = 'GET_ALLUSERS_FAILURE';

export const GET_ALLSUBSCRIBE_REQUEST = 'GET_ALLSUBSCRIBE_REQUEST';
export const GET_ALLSUBSCRIBE_SUCCESS = 'GET_ALLSUBSCRIBE_SUCCESS';
export const GET_ALLSUBSCRIBE_FAILURE = 'GET_ALLSUBSCRIBE_FAILURE';


export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const SEARCH_USERS_REQUEST = 'SEARCH_USERS_REQUEST';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';

export const SET_ALL_USERS_NUMBER = 'SET_ALL_USERS_NUMBER';
export const SET_ALL_USERS_PD = 'SET_ALL_USERS_PD';

export const SET_ALL_SUBSCRIBE_NUMBER = 'SET_ALL_SUBSCRIBE_NUMBER';


export const ISFETCHING_USERS_SET_DEFAULT = 'ISFETCHING_USERS_SET_DEFAULT';

// ORGANIZATION
export const ALL_ORGANIZATIONS_REQUEST = 'ALL_ORGANIZATIONS_REQUEST';
export const ALL_ORGANIZATIONS_SUCCESS = 'ALL_ORGANIZATIONS_SUCCESS';
export const ALL_ORGANIZATIONS_FAILURE = 'ALL_ORGANIZATIONS_FAILURE';
export const ALL_OSSLIST_SUCCESS = 'ALL_OSSLIST_SUCCESS';
export const ALL_OSSLIST_FAILURE = 'ALL_OSSLIST_FAILURE';


export const SET_ALL_OSSLIST_NUMBER = 'SET_ALL_OSSLIST_NUMBER';

export const ORGANIZATION_REGISTER_REQUEST = 'ORGANIZATION_REGISTER_REQUEST';
export const ORGANIZATION_REGISTER_SUCCESS = 'ORGANIZATION_REGISTER_SUCCESS';
export const ORGANIZATION_REGISTER_FAILURE = 'ORGANIZATION_REGISTER_FAILURE';

export const SET_ORGANIZATION_TYPE_FILTER = 'SET_ORGANIZATION_TYPE_FILTER';

export const SEARCH_ORGANIZATIONS_REQUEST = 'SEARCH_ORGANIZATIONS_REQUEST';
export const SEARCH_ORGANIZATIONS_SUCCESS = 'SEARCH_ORGANIZATIONS_SUCCESS';
export const SEARCH_ORGANIZATIONS_FAILURE = 'SEARCH_ORGANIZATIONS_FAILURE';

export const SEARCH_MY_ORGANIZATIONS_REQUEST = 'SEARCH_MY_ORGANIZATIONS_REQUEST';
export const SEARCH_MY_ORGANIZATIONS_SUCCESS = 'SEARCH_MY_ORGANIZATIONS_SUCCESS';
export const SEARCH_MY_ORGANIZATIONS_FAILURE = 'SEARCH_MY_ORGANIZATIONS_FAILURE';

export const GET_SINGLE_ORGANIZATION_REQUEST = 'GET_SINGLE_ORGANIZATION_REQUEST';
export const GET_SINGLE_ORGANIZATION_SUCCESS = 'GET_SINGLE_ORGANIZATION_SUCCESS';
export const GET_SINGLE_ORGANIZATION_FAILURE = 'GET_SINGLE_ORGANIZATION_FAILURE';

export const ORGANIZATION_UPDATE_REQUEST = 'ORGANIZATION_UPDATE_REQUEST';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_FAILURE = 'ORGANIZATION_UPDATE_FAILURE';

export const MY_ORGANIZATIONS_REQUEST = 'MY_ORGANIZATIONS_REQUEST';
export const MY_ORGANIZATIONS_SUCCESS = 'MY_ORGANIZATIONS_SUCCESS';
export const MY_ORGANIZATIONS_FAILURE = 'MY_ORGANIZATIONS_FAILURE';

export const UPLOAD_ORGANIZATION_LOGO_REQUEST = 'UPLOAD_ORGANIZATION_LOGO_REQUEST';
export const UPLOAD_ORGANIZATION_LOGO_SUCCESS = 'UPLOAD_ORGANIZATION_LOGO_SUCCESS';
export const UPLOAD_ORGANIZATION_LOGO_FAILURE = 'UPLOAD_ORGANIZATION_LOGO_FAILURE';

export const SET_ALL_ORGANIZATIONS_NUMBER = 'SET_ALL_ORGANIZATIONS_NUMBER';
export const SET_MY_ORGANIZATIONS_NUMBER = 'SET_MY_ORGANIZATIONS_NUMBER';

export const ISFETCHING_SINGLE_ORGANIZATION_SET_DEFAULT = 'ISFETCHING_SINGLE_ORGANIZATION_SET_DEFAULT';
export const ISFETCHING_LIST_ORGANIZATION_SET_DEFAULT = 'ISFETCHING_LIST_ORGANIZATION_SET_DEFAULT';

export const THEIR_ORGANIZATIONS_REQUEST = 'THEIR_ORGANIZATION_REQUEST';
export const THEIR_ORGANIZATIONS_SUCCESS = 'THEIR_ORGANIZATION_SUCCESS';
export const THEIR_ORGANIZATIONS_FAILURE = 'THEIR_ORGANIZATION_FAILURE';

export const CLEAR_MY_ORGANIZATIONS = 'CLEAR_MY_ORGANIZATIONS';

// ASSET
export const ALL_ASSET_REQUEST = 'ALL_ASSET_REQUEST';
export const ALL_ASSET_SUCCESS = 'ALL_ASSET_SUCCESS';
export const ALL_ASSET_FAILURE = 'ALL_ASSET_FAILURE';

export const ASSET_REGISTER_REQUEST = 'ASSET_REGISTER_REQUEST';
export const ASSET_REGISTER_SUCCESS = 'ASSET_REGISTER_SUCCESS';
export const ASSET_REGISTER_FAILURE = 'ASSET_REGISTER_FAILURE';

export const ASSET_UPDATE_REQUEST = 'ASSET_UPDATE_REQUEST';
export const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
export const ASSET_UPDATE_FAILURE = 'ASSET_UPDATE_FAILURE';

export const GET_SINGLE_ASSET_REQUEST = 'GET_SINGLE_ASSET_REQUEST';
export const GET_SINGLE_ASSET_SUCCESS = 'GET_SINGLE_ASSET_SUCCESS';
export const GET_SINGLE_ASSET_FAILURE = 'GET_SINGLE_ASSET_FAILURE';

export const GET_SINGLE_ASSET_ENERGY_CERTIFICATE  = 'GET_SINGLE_ASSET_ENERGY_CERTIFICATE';
export const GET_SINGLE_ASSET_ENERGY_CERTIFICATE_SUCCESS = 'GET_SINGLE_ASSET_ENERGY_CERTIFICATE_SUCCESS';
export const GET_SINGLE_ASSET_ENERGY_CERTIFICATE_FAILURE = 'GET_SINGLE_ASSET_ENERGY_CERTIFICATE_FAILURE';




export const SET_ASSET_TYPE_FILTER = 'SET_ASSET_TYPE_FILTER';

export const SEARCH_ASSETS_REQUEST = 'SEARCH_ASSETS_REQUEST';
export const SEARCH_ASSETS_SUCCESS = 'SEARCH_ASSETS_SUCCESS';
export const SEARCH_ASSETS_FAILURE = 'SEARCH_ASSETS_FAILURE';

export const SEARCH_MY_ASSETS_REQUEST = 'SEARCH_MY_ASSETS_REQUEST';
export const SEARCH_MY_ASSETS_SUCCESS = 'SEARCH_MY_ASSETS_SUCCESS';
export const SEARCH_MY_ASSETS_FAILURE = 'SEARCH_MY_ASSETS_FAILURE';

export const GET_MYASSETS_REQUEST = 'GET_MYASSETS_REQUEST';
export const GET_MYASSETS_SUCCESS = 'GET_MYASSETS_SUCCESS';
export const GET_MYASSETS_FAILURE = 'GET_MYASSETS_FAILURE';

export const SET_ALL_ASSETS_NUMBER = 'SET_ALL_ASSETS_NUMBER';
export const SET_MY_ASSETS_NUMBER = 'SET_MY_ASSETS_NUMBER';

export const SET_LOGGED_USER_ROLE_IN_ASSET = 'SET_LOGGED_USER_ROLE_IN_ASSET';
export const UNSET_LOGGED_USER_ROLE_IN_ASSET = 'UNSET_LOGGED_USER_ROLE_IN_ASSET';

export const ISFETCHING_SINGLE_ASSET_SET_DEFAULT = 'ISFETCHING_SINGLE_ASSET_SET_DEFAULT';
export const ISFETCHING_LIST_ASSET_SET_DEFAULT = 'ISFETCHING_LIST_ASSET_SET_DEFAULT';

export const CLEAR_MY_ASSETS = 'CLEAR_MY_ASSETS';

// PROJECT
export const ALL_PROJECT_REQUEST = 'ALL_PROJECT_REQUEST';
export const ALL_PROJECT_SUCCESS = 'ALL_PROJECT_SUCCESS';
export const ALL_PROJECT_FAILURE = 'ALL_PROJECT_FAILURE';

export const PROJECT_REGISTER_REQUEST = 'PROJECT_REGISTER_REQUEST';
export const PROJECT_REGISTER_SUCCESS = 'PROJECT_REGISTER_SUCCESS';
export const PROJECT_REGISTER_FAILURE = 'PROJECT_REGISTER_FAILURE';

export const MY_PROJECTS_REQUEST = 'MY_PROJECTS_REQUEST';
export const MY_PROJECTS_SUCCESS = 'MY_PROJECTS_SUCCESS';
export const MY_PROJECTS_FAILURE = 'MY_PROJECTS_FAILURE';

export const THEIR_PROJECTS_REQUEST = 'THEIR_PROJECTS_REQUEST';
export const THEIR_PROJECTS_SUCCESS = 'THEIR_PROJECTS_SUCCESS';
export const THEIR_PROJECTS_FAILURE = 'THEIR_PROJECTS_FAILURE';

export const SEARCH_PROJECTS_REQUEST = 'SEARCH_PROJECTS_REQUEST';
export const SEARCH_PROJECTS_SUCCESS = 'SEARCH_PROJECTS_SUCCESS';
export const SEARCH_PROJECTS_FAILURE = 'SEARCH_PROJECTS_FAILURE';

export const GET_SINGLE_PROJECT_REQUEST = 'GET_SINGLE_PROJECT_REQUEST';
export const GET_SINGLE_PROJECT_SUCCESS = 'GET_SINGLE_PROJECT_SUCCESS';
export const GET_SINGLE_PROJECT_FAILURE = 'GET_SINGLE_PROJECT_FAILURE';

export const SEARCH_MY_PROJECTS_REQUEST = 'SEARCH_MY_PROJECTS_REQUEST';
export const SEARCH_MY_PROJECTS_SUCCESS = 'SEARCH_MY_PROJECTS_SUCCESS';
export const SEARCH_MY_PROJECTS_FAILURE = 'SEARCH_MY_PROJECTS_FAILURE';

export const SET_ALL_PROJECTS_NUMBER = 'SET_ALL_PROJECTS_NUMBER';
export const SET_MY_PROJECTS_NUMBER = 'SET_MY_PROJECTS_NUMBER';

export const ISFETCHING_SINGLE_PROJECT_SET_DEFAULT = 'ISFETCHING_SINGLE_PROJECT_SET_DEFAULT';
export const ISFETCHING_LIST_PROJECT_SET_DEFAULT = 'ISFETCHING_LIST_PROJECT_SET_DEFAULT';

export const GET_MARKDOWN_REQUEST = 'GET_MARKDOWN_REQUEST';
export const GET_MARKDOWN_SUCCESS = 'GET_MARKDOWN_SUCCESS';
export const GET_MARKDOWN_FAILURE = 'GET_MARKDOWN_FAILURE';

export const UPDATE_MARKDOWN_REQUEST = 'UPDATE_MARKDOWN_REQUEST';
export const UPDATE_MARKDOWN_SUCCESS = 'UPDATE_MARKDOWN_SUCCESS';
export const UPDATE_MARKDOWN_FAILURE = 'UPDATE_MARKDOWN_FAILURE';

// ANNEX
export const TOGGLE_ACTIVE_ANNEX = 'TOGGLE_ACTIVE_ANNEX';

export const GET_BUDGET_TABLE_REQUEST = 'GET_BUDGET_TABLE_REQUEST';
export const GET_BUDGET_TABLE_SUCCESS = 'GET_BUDGET_TABLE_SUCCESS';
export const GET_BUDGET_TABLE_FAILURE = 'GET_BUDGET_TABLE_FAILURE';

export const GET_ENERGY_TABLE_REQUEST = 'GET_ENERGY_TABLE_REQUEST';
export const GET_ENERGY_TABLE_SUCCESS = 'GET_ENERGY_TABLE_SUCCESS';
export const GET_ENERGY_TABLE_FAILURE = 'GET_ENERGY_TABLE_FAILURE';

export const GET_MAINTENANCE_TABLE_REQUEST = 'GET_MAINTENANCE_TABLE_REQUEST';
export const GET_MAINTENANCE_TABLE_SUCCESS = 'GET_MAINTENANCE_TABLE_SUCCESS';
export const GET_MAINTENANCE_TABLE_FAILURE = 'GET_MAINTENANCE_TABLE_FAILURE';

export const GET_FEES_TABLE_REQUEST = 'GET_FEES_TABLE_REQUEST';
export const GET_FEES_TABLE_SUCCESS = 'GET_FEES_TABLE_SUCCESS';
export const GET_FEES_TABLE_FAILURE = 'GET_FEES_TABLE_FAILURE';

export const GET_ANNEXES_FIELDS_REQUEST = 'GET_ANNEXES_FIELDS_REQUEST';
export const GET_ANNEXES_FIELDS_SUCCESS = 'GET_ANNEXES_FIELDS_SUCCESS';
export const GET_ANNEXES_FIELDS_FAILURE = 'GET_ANNEXES_FIELDS_FAILURE';

export const UPDATE_BUDGET_TABLE_REQUEST = 'UPDATE_BUDGET_TABLE_REQUEST';
export const UPDATE_BUDGET_TABLE_SUCCESS = 'UPDATE_BUDGET_TABLE_SUCCESS';
export const UPDATE_BUDGET_TABLE_FAILURE = 'UPDATE_BUDGET_TABLE_FAILURE';

export const UPDATE_ENERGY_TABLE_REQUEST = 'UPDATE_ENERGY_TABLE_REQUEST';
export const UPDATE_ENERGY_TABLE_SUCCESS = 'UPDATE_ENERGY_TABLE_SUCCESS';
export const UPDATE_ENERGY_TABLE_FAILURE = 'UPDATE_ENERGY_TABLE_FAILURE';

export const UPDATE_MAINTENANCE_TABLE_REQUEST = 'UPDATE_MAINTENANCE_TABLE_REQUEST';
export const UPDATE_MAINTENANCE_TABLE_SUCCESS = 'UPDATE_MAINTENANCE_TABLE_SUCCESS';
export const UPDATE_MAINTENANCE_TABLE_FAILURE = 'UPDATE_MAINTENANCE_TABLE_FAILURE';

export const UPDATE_FEES_TABLE_REQUEST = 'UPDATE_FEES_TABLE_REQUEST';
export const UPDATE_FEES_TABLE_SUCCESS = 'UPDATE_FEES_TABLE_SUCCESS';
export const UPDATE_FEES_TABLE_FAILURE = 'UPDATE_FEES_TABLE_FAILURE';

export const UPDATE_ANNEXES_FIELDS_REQUEST = 'UPDATE_ANNEXES_FIELDS_REQUEST';
export const UPDATE_ANNEXES_FIELDS_SUCCESS = 'UPDATE_ANNEXES_FIELDS_SUCCESS';
export const UPDATE_ANNEXES_FIELDS_FAILURE = 'UPDATE_ANNEXES_FIELDS_FAILURE';

export const GET_FORFAITING_AGREEMENT_REQUEST = 'GET_FORFAITING_AGREEMENT_REQUEST';
export const GET_FORFAITING_AGREEMENT_SUCCESS = 'GET_FORFAITING_AGREEMENT_SUCCESS';
export const GET_FORFAITING_AGREEMENT_FAILURE = 'GET_FORFAITING_AGREEMENT_FAILURE';

export const FOREFAITING_AGREEMENT_UPDATE_REQUEST = 'FOREFAITING_AGREEMENT_UPDATE_REQUEST';
export const FOREFAITING_AGREEMENT_UPDATE_SUCCESS = 'FOREFAITING_AGREEMENT_UPDATE_SUCCESS';
export const FOREFAITING_AGREEMENT_UPDATE_FAILURE = 'FOREFAITING_AGREEMENT_UPDATE_FAILURE';

export const DOWNLOAD_CONTRACT_REQUEST = 'DOWNLOAD_CONTRACT_REQUEST';
export const DOWNLOAD_CONTRACT_SUCCESS = 'DOWNLOAD_CONTRACT_SUCCESS';
export const DOWNLOAD_CONTRACT_FAILURE = 'DOWNLOAD_CONTRACT_FAILURE';

export const CREATE_SUPERUSER_REQUEST = 'CREATE_SUPERUSER_REQUEST';
export const CREATE_SUPERUSER_SUCCESS = 'CREATE_SUPERUSER_SUCCESS';
export const CREATE_SUPERUSER_FAILURE = 'CREATE_SUPERUSER_FAILURE';

export const UPDATE_ANOTHER_USER_PROFILE_REQUEST = 'UPDATE_ANOTHER_USER_PROFILE_REQUEST';
export const UPDATE_ANOTHER_USER_PROFILE_SUCCESS = 'UPDATE_ANOTHER_USER_PROFILE_SUCCESS';
export const UPDATE_ANOTHER_USER_PROFILE_FAILURE = 'UPDATE_ANOTHER_USER_PROFILE_FAILURE';

export const PROJECT_UPDATE_REQUEST = 'PROJECT_UPDATE_REQUEST';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_UPDATE_FAILURE = 'PROJECT_UPDATE_FAILURE';

export const SET_LOGGED_USER_ROLE = 'SET_LOGGED_USER_ROLE';
export const UNSET_LOGGED_USER_ROLE = 'UNSET_LOGGED_USER_ROLE';

export const SET_LOGGED_USER_ROLE_IN_ORGANIZATION = 'SET_LOGGED_USER_ROLE_IN_ORGANIZATION';
export const UNSET_LOGGED_USER_ROLE_IN_ORGANIZATION = 'UNSET_LOGGED_USER_ROLE_IN_ORGANIZATION';

export const ADD_ADDITIONAL_ROW = 'ADD_ADDITIONAL_ROW';

export const SET_PROJECT_TYPE_FILTER = 'SET_PROJECT_TYPE_FILTER';

export const CLEAR_PUBLIC_USER_DATA = 'CLEAR_PUBLIC_USER_DATA';
export const SET_PUBLIC_USER_DATA_READY = 'SET_PUBLIC_USER_DATA_READY';

export const UPDATE_INDOOR_CLIMA_REQUEST = 'UPDATE_INDOOR_CLIMA_REQUEST';
export const UPDATE_INDOOR_CLIMA_SUCCESS = 'UPDATE_INDOOR_CLIMA_SUCCESS';
export const UPDATE_INDOOR_CLIMA_FAILURE = 'UPDATE_INDOOR_CLIMA_FAILURE';

export const ANNEX_READY = 'ANNEX_READY';

export const REFETCH_SINGLE_PROJECT_REQUEST = 'REFETCH_SINGLE_PROJECT_REQUEST';
export const REFETCH_SINGLE_PROJECT_SUCCESS = 'REFETCH_SINGLE_PROJECT_SUCCESS';
export const REFETCH_SINGLE_PROJECT_FAILURE = 'REFETCH_SINGLE_PROJECT_FAILURE';

// reports
export const GET_PROJECTS_REPORTS_REQUEST = 'GET_PROJECTS_REPORTS_REQUEST';
export const GET_PROJECTS_REPORTS_SUCCESS = 'GET_PROJECTS_REPORTS_SUCCESS';
export const GET_PROJECTS_REPORTS_FAILURE = 'GET_PROJECTS_REPORTS_FAILURE';

export const GET_USERS_REPORTS_REQUEST = 'GET_USERS_REPORTS_REQUEST';
export const GET_USERS_REPORTS_SUCCESS = 'GET_USERS_REPORTS_SUCCESS';
export const GET_USERS_REPORTS_FAILURE = 'GET_USERS_REPORTS_FAILURE';

export const GET_SUBSCRIBE_REPORTS_REQUEST = 'GET_SUBSCRIBE_REPORTS_REQUEST';
export const GET_SUBSCRIBE_REPORTS_SUCCESS = 'GET_SUBSCRIBE_REPORTS_SUCCESS';
export const GET_SUBSCRIBE_REPORTS_FAILURE = 'GET_SUBSCRIBE_REPORTS_FAILURE';


export const GET_APPROVED_ASSETS_REQUEST = 'GET_APPROVED_ASSETS_REQUEST';
export const GET_APPROVED_ASSETS_SUCCESS = 'GET_APPROVED_ASSETS_SUCCESS';
export const GET_APPROVED_ASSETS_FAILURE = 'GET_APPROVED_ASSETS_FAILURE';

export const SET_APPROVED_ASSETS_NUMBER = 'SET_APPROVED_ASSETS_NUMBER';