import React from "react";
import {
  Home as HomeIcon,
  BusinessCenter as OrganizationIcon,
  Business as AssetIcon,
  Equalizer as ProjectIcon,
  Security as AdminIcon,
  TableChart as TableChartIcon,
  Delete as DeleteIcon,
  Add as PlusIcon,
} from '@material-ui/icons';
import ReactTags from 'react-tag-autocomplete'
import './style.css';


const ResultDataTable4 = props => {
   
    return props.tabStepResult6.filter(item => item.data.table_type === 'After' ).map((menu, index) => {  
        let first_year_value = `first_year_value-${index}`,
        unit_vat_xcl = `unit_vat_xcl-${index}`,
        cost_vat_inc = `cost_vat_inc-${index}`,
        unit_vat_inc = `unit_vat_inc-${index}`,
        vat = `vat-${index}`,
        total_vat_xcl = `total_vat_xcl-${index}`,
        total_vat_inc = `total_vat_inc-${index}`,
        result_title = `result_title-${index}`;
        
        return (
        
       
                    <tr key={index}>
                        <td>  <DeleteIcon  style={{ width: '2em',height: '2em'}} onClick={() => props.deleteResultRow(menu.data.ID)} /></td>
                         <td style={{ width: '20%'}}><input type="text" className="input mt-2" id={result_title} name="result_title" onBlur={(e) => props.resultValueUpdate(e,menu.data.ID,index,'After')} defaultValue={menu.data.result_title} style={{width: '100%',fontSize: '15px',fontWeight: '900',borderWidth: '1px' ,border: 'none',borderBottom: '0.89246px solid #BFD4E4'}}  /></td>
                      
                         <td style={{ width: '30%'}}> 
                        <ReactTags className="input mt-2"
                                            ref={props.reactTags}
                                            tags={props.tags[0].first_year_value[index]}
                                            suggestions={props.suggestions}
                                            onDelete={(e) =>props.onDelete(e,props.tags[0].first_year_value[index]) }                                           
                                            onAddition={(e) =>props.onAddition(e,'first_year_value',menu.data.ID,index) } minQueryLength={1}  />                        
                        </td>  
                        
                    </tr>
               
                
               );
      
            });
  
};


export { ResultDataTable4 };
