import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
    Grid,
    makeStyles,
} from '@material-ui/core';


import ProgressBar from '../../../../../components/utils/ProgressBar';
import NavContainer from '../../../../smartcomponents/EESNavcontainer';
import EESFooter from '../../../../smartcomponents/EESFooter';
import SelectLanguageModel from '../../../../smartcomponents/SelectLanguageModel';
import EESNavigationMenu from '../../../../smartcomponents/EESNavigationMenu';

import {
    Home as HomeIcon,
    BusinessCenter as OrganizationIcon,
    Business as AssetIcon,
    Equalizer as ProjectIcon,
    Security as AdminIcon,
    TableChart as TableChartIcon,
    Delete as DeleteIcon,
    CalendarTodaySharp as PlusIcon,
} from '@material-ui/icons';


import '../../../../../styles/assets/css/bootstrap.min.css';
import '../../../../../styles/assets/css/stepwizard.css';
//images
import logo_footer2 from '../../../../../styles/assets/images/logo-footer2.png';
import Logo_europa_White from '../../../../../images/3SUNShiNE_Black.svg';
import calculaadora from '../../../../../styles/assets/calculaadora.png';
import quizHomeIcon from '../../../../../styles/assets/quizHomeIcon.png';

import icon_calc from '../../../../../styles/assets/images/icon-calc.png';
import energy_drivers from '../../../../../styles/assets/energy_drivers.png';
import economic_text from '../../../../../styles/assets/economic_text.png';
import bolt_1 from '../../../../../styles/assets/bolt_1.png';
import database from '../../../../../styles/assets/database.png';


import getCookie from '../../../../../components/utils/getCookie';
import { alert, defaultModules } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import * as PNotifyMobile from '@pnotify/mobile';
import '@pnotify/mobile/dist/PNotifyMobile.css';

import { useSelector } from 'react-redux';
import ENDPOINTS from '../../../../../constants/endpoints';
import { setCalcMenuItems } from '../../../../../actions/calcmenu';

import {
    searchUsers as searchUsersAction,
    getUsersByPlatformRoles as getPRUsersAction,
} from '../../../../../actions/users';
import publicIp from 'public-ip';

import Vector1 from '../../../../../styles/assets/Vector1.png';
import snowflake_1 from '../../../../../styles/assets/snowflake_1.png';

class ServiceOperatorChecklist extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            stepDataNew: [],
            errors: {},
            fetching: false,
            skipQuestion: false,
            basicInformation: {
                                country:this.props.selectedCountry,
                                type:'Service Operator',
                                postCode:this.props.postCode,
                                name:'',
                                surname:'',
                                email:'',
                                type_of_organization:'',
                                organization_name:'',
                                registration_number:'',
                                vat_number:'',
                                legal_rep_org:'',
                                website:'',
                                phoneNumber:'',
                                address:'',
                                city:'',
                                agree_checkbox:'',    
                                registration_date:''                            
                              },
                        questionData: {                         
                            field_id:'',
                            field_value:[],
                            field_title:'',                               
                               
                               }, 
                               hobbies:[],                                   
            createdUserDetails: [],                 
            countLi:0,

        }
       // this.countryChange = this.countryChange.bind(this);
       // this.residentsTypeChange = this.residentsTypeChange.bind(this);
        //this.onDelete =this.onDelete.bind(this);

        this.questionDataChange = this.questionDataChange.bind(this);
        this.getOssStepData = this.getOssStepData.bind(this);

        
    }
    

    questionDataChange = (e,type,field_id,field_title) => {

      const target = e.target;
      var value = target.value;
      

      if(type === 'Checkbox Type Box' || type === 'Checkbox Type Button'){
        if(target.checked){
          this.state.hobbies[value]= value;   
          }else{
            this.state.hobbies.splice(value, 1);
            //const newArray = this.state.hobbies.filter((item) => item.nameValue ===value);
          
          
          }

      }else{

        //if(target.checked){
          this.state.hobbies= [value];   
         // }else{
           // this.state.hobbies.splice(value, 1);
            //const newArray = this.state.hobbies.filter((item) => item.nameValue ===value);
        
          
         // }
      }
      
      
       
    }
    validate=(type,currentTab,activeTabeCount) => {
      let val = true;
     
      let select = document.getElementById(currentTab);
      
      select.querySelectorAll('#radio-container'+activeTabeCount).forEach(function(container) 
      {
      

        let radioChecked = container.querySelectorAll("input:checked");


        if(!radioChecked.length) {
          val = val && false;
          document.querySelector('#output'+activeTabeCount).innerText = "Please select answer";
         // container.append(" <b>Appended text</b>.");;
        }
      });
      return val;
    }
     handleNextStep = (type,stepIndex,prvStep,activeTabeCount,filedId,filedTitle,validationId) => {
     
      if (this.validate(type,prvStep,validationId)){
        let errors = {};
        this.setState({
          fetching:true,
          
        })
        const str = Object.values(this.state.hobbies).join(',');
     
      if(filedTitle === 'Are you an ESCO?' && str === 'No'){        
        this.state.skipQuestion = true
        this.forceUpdate()
      }

      
  //      if(this.state.hobbies.length === 0){
  
  // errors["nextRequired"] = "please select one value";
  // this.setState({ errors: errors });
  
  //      }else{
    this.setState({
      fetching:false,
      
    })
    $(".tab-pane").removeClass("active show");
    $('#'+stepIndex).addClass('active show');
   // $(".nav-link").removeClass("active show");
   // $('li[id=tabStep-' + activeTabeCount + ']').find('a').addClass("active show")
   // $("#customeTab > li:has(a[href='#tab-" +activeTabeCount+ "']):first()").find('a').addClass("active show")
    this.state.hobbies = []; 
    errors["nextRequired"] = "";
    this.setState({ errors: errors });
    this.forceUpdate()
    $("#output"+validationId).text('');
  
    
      }

      
     
      //}
    }

    lastStepSubmit = (type,stepIndex,prvStep,activeTabeCount,filedId,filedTitle,validationId) => {

      if (this.validate(type,prvStep,validationId)){

      let errors = {};
      this.setState({
        fetching:true,
        
      })
//      if(this.state.hobbies.length === 0){

// errors["nextRequired"] = "please select one value";
// this.setState({ errors: errors });

//      }else{
      const str = Object.values(this.state.hobbies).join(',');
      this.state.hobbies = []; 
      errors["nextRequired"] = "";
      this.setState({ errors: errors });
      this.forceUpdate()
      this.props.serviceOperatorChecklistLastStepSubmit();
      this.setState({
       fetching:false,
       
     })
      

      }
      //}
    }
    
  
    handlePrevStep = (stepIndex,prvStep,activeTabeCount) => {
      let activeTbale = activeTabeCount;
      let kkk = prvStep - 1;
      this.state.skipQuestion = false
        this.forceUpdate()
       // $('#'+tttt).removeClass('active show');
       //$('#'+stepIndex).removeClass('active show');
        //$('#'+prvStep).addClass('active show');
        $(".tab-pane").removeClass("active show");
       // $('#'+stepIndex).removeClass('active show');
        $('#tab-'+kkk).addClass('active show');

        $(".nav-link").removeClass("active show");

        $('li[id=tabStep-' + activeTbale + ']').find('a').addClass("active show");

        //$("#customeTab > li:has(a[href='#tab-" +activeTbale+ "']):first()").find('a').addClass("active show")
       // $("#customeTab > li:has(a[href='#tab-" +removeactiveTbale+ "']):first()").find('a').removeClass("active show")
       // $( "#mytabs > ul li:nth-child(2)" ).find('a').trigger('click')
        //$('#customeTab > .nav-item > .active').next('li').eq( 2 ).find('a').trigger('click');
    }
  
     

    componentDidMount(){       
        this.getOssStepData();
        
    
      }
    getOssStepData =()=>{
      this.setState({
        fetching:true,
        
      }) 
      const menu_path = window.location.pathname.split('/')[2];
    
      let is_default_template = 0;
      if(menu_path === 'admin'){
  
         is_default_template = 1;
      }

        const config = {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'text/plain' },
            body: JSON.stringify({
              "oss_admin_id":this.props.selectedOssAdmin,
              "lang":this.props.language,     
              "menu_type":'Service Operator',
              //"is_default_template":this.props.isDefaultTemplateData 
              "is_default_template":is_default_template
              
            })
          };          
          
          fetch(ENDPOINTS.SERVER + '/onboarding/residents/step', config)
              .then(res => res.json())
             // .then((result) => result.length ? JSON.parse(text) : {})
              .then(
                  (result) => {   
               
                    if (result != null) {
                        let resultData = result.documents.filter(item => item.data.name === this.props.selectedOrganizationType  )
                     
                       
                         this.setState({
                            stepDataNew: resultData,
                            
                          }) 
                          this.state.createdUserId ='pppp'
                          this.setState({
                            fetching:false,
                            
                          }) 
                             this.forceUpdate()

                                                                    
                        }
                  },
                  
              ).catch(error => {
                //setTabStep([]);                
            });
    }   
    
   


render() { 

    let count =3;
    let count1 = 0;
    let basicSubCount = 0;
    let radioButtonCount =0;
    let nextStepcount = 4;
    let prvStepcount = 3;
    let backnextStepcount = 4;
    let backprvStepcount = 3;
    let activeTable      = 0;

    return (
        <React.Fragment>
            <div className="row row-eq-height align-middle my-auto animate__animated animate__fadeInRight"  >

                                <div className="col-md-12">
                               

                               <section className="signup-step-container" style={{ marginTop:'10px',marginBottom:'0px' }}>
                                   <div className="container">
                                       <div className="row d-flex ">
                                           <div className="col-md-12">
                                               <div className="wizard">
                                                       <div className="tab-content mb-5">
                                                         
                                                       {this.state.fetching
                                                              ?
                                                                  <ProgressBar />
                                                              :
                                                              <> 
                                                                
                                                               
                                                       {this.state.stepDataNew.map((stepData, stepKey) => {
                                                         let activeTabeCount = activeTable++;
                                                           return ( <>
                                                            {(() => {
                                                                   if (this.state.skipQuestion === true && this.props.selectedOrganizationType  ==='Operator') {
                                                                    return ( <>
                                                                       {stepData.data.questions.filter(img => img.name != 'Are you certified according to national standards or qualification system regulation?'
                                                                       
                                                                                                          ).sort((a, b) => a.CreatedAt > b.CreatedAt ? 1:-1).map((questionData,questionsKey, arr) => {
                                                                             let pppp = nextStepcount++;
                                                                             let kkkk = prvStepcount++;
                                                                             let yyy = backnextStepcount++;
                                                                             let oooo = backprvStepcount++;
                                                                             return (<div className={stepKey === 0 &&questionsKey === 0 ? "tab-pane active show" : "tab-pane"} id={'tab-' + count++} key={questionsKey}>
                                                                                <>
                                                                                    {(() => {
                                                                                      
                                                                                          return <div><h2 className="mb-3 mt-3">{questionData.name}</h2>
                                                                                          <span id={'output'+pppp}  style={{ color:'red',fontSize: '14px',float: 'left' }}></span>
                                                                                          <br></br>
                                                                                          <span style={{ color: "red" }}>{this.state.errors["nextRequired"]}</span>
                                                                                          {questionData.step_fields.sort((a, b) => a.index > b.index ? 1 : -1).map((c, i) => (

                                                                                              <>
                                                                                              <div  id={'radio-container'+pppp}>
                                                                                              {(() => {
                                                                                                
                                                                                                if(c.input_type === 'Text' || c.input_type === 'Number'){ 
                                                                                                  return<div className="col-md-6 mb-2 " key={i}>
                                                                                                            <label htmlFor="inputEmail4">{c.name}
                                                                                                                {(() => {
                                                                                                                    if (c.require == true) {
                                                                                                                        return "*";
                                                                                                                    }
                                                                                                                })()}
                                                                                                            </label>

                                                                                                            <input type={c.text_number} onBlur={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}  className="form-control quantityDropdown"
                                                                                                                placeholder={c.placeholder} />

                                                                                                        </div>
                                                                                                  }

                                                                                                  if (c.input_type === 'Dropdown') {                                                                                                   
                                                                                                    return<div className="col-md-6 mb-2 " key={i}>                                                                                                       
                                                                                                      <div className="form-group">
                                                                                                            <label htmlFor="inputEmail4">{c.name}</label>
                                                                                                            <select className="form-control quantityDropdown" name="" onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}
                                                                                                              style={{background: 'none',color:'#6B7280',fontWeight: '500',fontSize: '11.602px'}}>
                                                                                                                <option value="">Select {c.name}</option>
                                                                                                                  {c.options.sort((a, b) => a.CreatedAt < b.CreatedAt ? 1:-1).map((optionValue, optionKey) => {
                                                                                                          
                                                                                                                                        return ( 
                                                                                                                                          <option value={optionValue.option_value}>{optionValue.option_value}</option>
                                                                                                                                        );
                                                                                                                              })                                                                                                                                      
                                                                                                                              }                                                                                                               
                                                                                                            </select>
                                                                                                          </div>                                                                                                                                                                                                                                                                                                       
                                                                                                    </div>                                                                                                                                                                                                       
                                                                                                  }
                                                                                                    if (c.input_type === 'Radio Type Button') {      

                                                                                                      return <div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2"> 
                                                                                                          <div className="row mb-2">
                                                                                                              {c.options.map((optionValue, optionKey) => (    
                                                                                                                                                                                                                                                                                                                                          
                                                                                                                              <div className="col-md-3" id="step5chk">                                                    
                                                                                                                                <input type="radio" id={optionValue.ID}  onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                              </div>
                                                                                                                              ))
                                                                                                            }       
                                                                                                            </div>                                                                                                                                                                                                                                                                                           
                                                                                                      </div>   
                                                                                                      </div>                                                                                                                                                                                                    
                                                                                                    }    
                                                                                                    
                                                                                                    if (c.input_type === 'Checkbox Type Button') {     
                                                                                                                                                                                              
                                                                                                      return<div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2" key={i}> 
                                                                                                      <div className="col-md-12" >
                                                                                                                <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                              </div>
                                                                                                            <br></br>
                                                                                                            <br></br>
                                                                                                      <div className="row mb-2">
                                                                                                              {c.options.map((optionValue, optionKey) => (                                                                                                                                                                                                                               
                                                                                                                              <div className="col-md-3" id="step5chk">
                                                                                                                                <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                              </div>
                                                                                                                              ))
                                                                                                            }       
                                                                                                            </div>                                                                                                                                                                                                                                                                                           
                                                                                                      </div>  
                                                                                                      </div>                                                                                                                                                                                                     
                                                                                                    }

                                                                                                    if (c.input_type === 'Checkbox Type Box') {   
                                                                                                                                                                                                      
                                                                                                      return <div className="row mb-2 mt-2" key={i}> 
                                                                                                              <div className="col-md-12" >
                                                                                                                <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                              </div>
                                                                                                            <br></br>
                                                                                                            <br></br>
                                                                                                              {c.options.map((optionValue, optionKey) => (     
                                                                                                                <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                            <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}   name="hobbies" value={optionValue.option_value}/>
                                                                                                                                  <label htmlFor={optionValue.ID}>
                                                                                                                                    <div className="serBox">
                                                                                                                                      <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                        <div className="col-md-12">
                                                                                                                                        <center>                                                                                                                                           
                                                                                                                                            <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                            <img src={snowflake_1} alt="" style={{marginTop: '37px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                          </center>
                                                                                                                                        </div>
                                                                                                                                      </div>
                                                                                                                                      <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                        id="test">
                                                                                                                                        <div className="col-md-12">
                                                                                                                                          <p> {optionValue.option_value}</p>
                                                                                                                                        </div>
                                                                                                                                      </div>
                                                                                                                                    </div>
                                                                                                                                  </label>
                                                                                                                              </div>
                                                                                                                              ))
                                                                                                            }       
                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                      </div>                                                                                                                                                                                                       
                                                                                                    }

                                                                                                    if (c.input_type === 'Radio Type Box') {                                                                                                   
                                                                                                      return<div className="row mb-5 mt-5" key={i}> 
                                                                                                        
                                                                                                              {c.options.map((optionValue, optionKey) => (     
                                                                                                                <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                            <input type="radio" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID}value={optionValue.option_value}/>
                                                                                                                                  <label htmlFor={optionValue.ID}>
                                                                                                                                    <div className="serBox">
                                                                                                                                      <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                        <div className="col-md-12">
                                                                                                                                        <center>                                                                                                                                           
                                                                                                                                            <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                            <img src={snowflake_1} alt="" style={{marginTop: '33px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                          </center>
                                                                                                                                        </div>
                                                                                                                                      </div>
                                                                                                                                      <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                        id="test">
                                                                                                                                        <div className="col-md-12">
                                                                                                                                          <p> {optionValue.option_value}</p>
                                                                                                                                        </div>
                                                                                                                                      </div>
                                                                                                                                    </div>
                                                                                                                                  </label>
                                                                                                                              </div>
                                                                                                                              ))
                                                                                                            }       
                                                                                                                                                                                                                                                                                                                                                                                                    
                                                                                                      </div>                                                                                                                                                                                                       
                                                                                                    }
                                                                                              })()}
                                                                                              </div>
                                                                                              <br></br>
                                                                                              <br></br>
                                                                                              <br></br>
                                                                                              {(() => {
                                                                                              
                                                                                              if (questionsKey === 0  && stepKey === 0) {
                                                                                                return <ul className="list-inline pull-left">
                                                                                                  {/* <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.props.backFirstStep()}>Back</button>
                                                                                                        </li> */}

                                                                                                <li> <button type="button"
                                                                                                    className="btn btn-primary btn-md btn-default next-step-sub" onClick={() => this.handleNextStep(c.input_type,'tab-' + pppp,'tab-' + kkkk ,activeTabeCount,c.ID,questionData.name,pppp)}>Next</button>
                                                                                                </li>
                                                                                              </ul>

                                                                                              }else if(stepData.data.questions.length ===  questionsKey + 1 && this.state.stepDataNew.length !==  stepKey + 1){
                                                                                                return <ul className="list-inline pull-left">
                                                                                                  
                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                        </li>

                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default next-step-sub" onClick={() => this.handleNextStep(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount + 1,c.ID,questionData.name,pppp)}>Next</button>
                                                                                                        </li>
                                                                                                      </ul>

                                                                                              }else if(this.state.stepDataNew.length ===  stepKey + 1 && questionsKey === 0 ){
                                                                                                return <ul className="list-inline pull-left">
                                                                                                      <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                        </li>
                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default next-step-sub"  onClick={() => this.lastStepSubmit(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount + 1,c.ID,questionData.name,pppp)}>Submit</button>
                                                                                                        </li>

                                                                                                      </ul>

                                                                                              }else if(this.state.stepDataNew.length ===  stepKey + 1 && stepData.data.questions.length  === questionsKey +2 ){
                                                                                                return <ul className="list-inline pull-left">
                                                                                                      <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                        </li>
                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default next-step-sub"  onClick={() => this.lastStepSubmit(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount + 1,c.ID,questionData.name,pppp)}>Submit</button>
                                                                                                        </li>

                                                                                                      </ul>

                                                                                              }  else {
                                                                                                return <ul className="list-inline pull-left">
                                                                                                
                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                        </li>

                                                                                                        <li> <button type="button"
                                                                                                            className="btn btn-primary btn-md btn-default next-step-sub" onClick={() => this.handleNextStep(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount,c.ID,questionData.name,pppp)}>Next</button>
                                                                                                        </li>
                                                                                                      </ul>
                                                                                              }
                                                                                                })()} 
                                                                                              </>

                                                                                              ))}
                                                                                          
                                                                                          </div>
                                                                                           

                                                                                        
                                                                                    
                                                                                              
                                                                                            })()} 
                                                                                            </>           
                                                                                      </div>);
                                                                                      
                                                                                    }) }


                                                                                    </>);

                                                                                  }else if (this.state.skipQuestion === false && this.props.selectedOrganizationType  ==='Operator') {

                                                                                    return ( <>

                                                                                      {stepData.data.questions.filter(img => img.name === 'Are you an ESCO?'  || img.name === 'Are you certified according to national standards or qualification system regulation?'
                                                                                                                                
                                                                                                     ).sort((a, b) => a.CreatedAt > b.CreatedAt ? 1:-1).map((questionData,questionsKey, arr) => {
                                                                                        let pppp = nextStepcount++;
                                                                                        let kkkk = prvStepcount++;
                                                                                        let yyy = backnextStepcount++;
                                                                                        let oooo = backprvStepcount++;
                                                                                        return (<div className={stepKey === 0 &&questionsKey === 0 ? "tab-pane active show" : "tab-pane"} id={'tab-' + count++} key={questionsKey}>
                                                                                           <>
                                                                                               {(() => {
                                                                                                 
                                                                                                     return <div><h2 className="mb-3 mt-3">{questionData.name}</h2>
                                                                                                     <span id={'output'+pppp}  style={{ color:'red',fontSize: '14px',float: 'left' }}></span>
                                                                                                     <br></br>
                                                                                                     <span style={{ color: "red" }}>{this.state.errors["nextRequired"]}</span>
                                                                                                     {questionData.step_fields.sort((a, b) => a.index > b.index ? 1 : -1).map((c, i) => (
           
                                                                                                         <>
                                                                                                         <div  id={'radio-container'+pppp}>
                                                                                                         {(() => {
                                                                                                           
                                                                                                           if(c.input_type === 'Text' || c.input_type === 'Number'){ 
                                                                                                             return<div className="col-md-6 mb-2 " key={i}>
                                                                                                                       <label htmlFor="inputEmail4">{c.name}
                                                                                                                           {(() => {
                                                                                                                               if (c.require == true) {
                                                                                                                                   return "*";
                                                                                                                               }
                                                                                                                           })()}
                                                                                                                       </label>
           
                                                                                                                       <input type={c.text_number} onBlur={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}  className="form-control quantityDropdown"
                                                                                                                           placeholder={c.placeholder} />
           
                                                                                                                   </div>
                                                                                                             }
           
                                                                                                             if (c.input_type === 'Dropdown') {                                                                                                   
                                                                                                               return<div className="col-md-6 mb-2 " key={i}>                                                                                                       
                                                                                                                 <div className="form-group">
                                                                                                                       <label htmlFor="inputEmail4">{c.name}</label>
                                                                                                                       <select className="form-control quantityDropdown" name="" onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}
                                                                                                                         style={{background: 'none',color:'#6B7280',fontWeight: '500',fontSize: '11.602px'}}>
                                                                                                                           <option value="">Select {c.name}</option>
                                                                                                                             {c.options.sort((a, b) => a.CreatedAt < b.CreatedAt ? 1:-1).map((optionValue, optionKey) => {
                                                                                                                     
                                                                                                                                                   return ( 
                                                                                                                                                     <option value={optionValue.option_value}>{optionValue.option_value}</option>
                                                                                                                                                   );
                                                                                                                                         })                                                                                                                                      
                                                                                                                                         }                                                                                                               
                                                                                                                       </select>
                                                                                                                     </div>                                                                                                                                                                                                                                                                                                       
                                                                                                               </div>                                                                                                                                                                                                       
                                                                                                             }
                                                                                                               if (c.input_type === 'Radio Type Button') {      
           
                                                                                                                 return <div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2"> 
                                                                                                                     <div className="row mb-2">
                                                                                                                         {c.options.map((optionValue, optionKey) => (    
                                                                                                                                                                                                                                                                                                                                                     
                                                                                                                                         <div className="col-md-3" id="step5chk">                                                    
                                                                                                                                           <input type="radio" id={optionValue.ID}  onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                           <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                                         </div>
                                                                                                                                         ))
                                                                                                                       }       
                                                                                                                       </div>                                                                                                                                                                                                                                                                                           
                                                                                                                 </div>   
                                                                                                                 </div>                                                                                                                                                                                                    
                                                                                                               }    
                                                                                                               
                                                                                                               if (c.input_type === 'Checkbox Type Button') {     
                                                                                                                                                                                                         
                                                                                                                 return<div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2" key={i}> 
                                                                                                                 <div className="col-md-12" >
                                                                                                                           <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                                         </div>
                                                                                                                       <br></br>
                                                                                                                       <br></br>
                                                                                                                 <div className="row mb-2">
                                                                                                                         {c.options.map((optionValue, optionKey) => (                                                                                                                                                                                                                               
                                                                                                                                         <div className="col-md-3" id="step5chk">
                                                                                                                                           <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                           <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                                         </div>
                                                                                                                                         ))
                                                                                                                       }       
                                                                                                                       </div>                                                                                                                                                                                                                                                                                           
                                                                                                                 </div>  
                                                                                                                 </div>                                                                                                                                                                                                     
                                                                                                               }
           
                                                                                                               if (c.input_type === 'Checkbox Type Box') {   
                                                                                                                                                                                                                 
                                                                                                                 return <div className="row mb-2 mt-2" key={i}> 
                                                                                                                         <div className="col-md-12" >
                                                                                                                           <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                                         </div>
                                                                                                                       <br></br>
                                                                                                                       <br></br>
                                                                                                                         {c.options.map((optionValue, optionKey) => (     
                                                                                                                           <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                                       <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}   name="hobbies" value={optionValue.option_value}/>
                                                                                                                                             <label htmlFor={optionValue.ID}>
                                                                                                                                               <div className="serBox">
                                                                                                                                                 <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                                   <div className="col-md-12">
                                                                                                                                                   <center>                                                                                                                                           
                                                                                                                                                       <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                                       <img src={snowflake_1} alt="" style={{marginTop: '37px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                                     </center>
                                                                                                                                                   </div>
                                                                                                                                                 </div>
                                                                                                                                                 <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                                   id="test">
                                                                                                                                                   <div className="col-md-12">
                                                                                                                                                     <p> {optionValue.option_value}</p>
                                                                                                                                                   </div>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                             </label>
                                                                                                                                         </div>
                                                                                                                                         ))
                                                                                                                       }       
                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                 </div>                                                                                                                                                                                                       
                                                                                                               }
           
                                                                                                               if (c.input_type === 'Radio Type Box') {                                                                                                   
                                                                                                                 return<div className="row mb-5 mt-5" key={i}> 
                                                                                                                   
                                                                                                                         {c.options.map((optionValue, optionKey) => (     
                                                                                                                           <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                                       <input type="radio" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID}value={optionValue.option_value}/>
                                                                                                                                             <label htmlFor={optionValue.ID}>
                                                                                                                                               <div className="serBox">
                                                                                                                                                 <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                                   <div className="col-md-12">
                                                                                                                                                   <center>                                                                                                                                           
                                                                                                                                                       <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                                       <img src={snowflake_1} alt="" style={{marginTop: '33px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                                     </center>
                                                                                                                                                   </div>
                                                                                                                                                 </div>
                                                                                                                                                 <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                                   id="test">
                                                                                                                                                   <div className="col-md-12">
                                                                                                                                                     <p> {optionValue.option_value}</p>
                                                                                                                                                   </div>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                             </label>
                                                                                                                                         </div>
                                                                                                                                         ))
                                                                                                                       }       
                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                 </div>                                                                                                                                                                                                       
                                                                                                               }
                                                                                                         })()}
                                                                                                         </div>
                                                                                                         <br></br>
                                                                                                         <br></br>
                                                                                                         <br></br>
                                                                                                         {(() => {
                                                                                                         
                                                                                                         if (questionsKey === 0  && stepKey === 0) {
                                                                                                           return <ul className="list-inline pull-left">
                                                                                                             {/* <li> <button type="button"
                                                                                                                       className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.props.backFirstStep()}>Back</button>
                                                                                                                   </li> */}
           
                                                                                                           <li> <button type="button"
                                                                                                               className="btn btn-primary btn-md btn-default next-step-sub" onClick={() => this.handleNextStep(c.input_type,'tab-' + pppp,'tab-' + kkkk ,activeTabeCount,c.ID,questionData.name,pppp)}>Next</button>
                                                                                                           </li>
                                                                                                         </ul>
           
                                                                                                         }else{
                                                                                                           return <ul className="list-inline pull-left">
                                                                                                                 <li> <button type="button"
                                                                                                                       className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                                   </li>
                                                                                                                   <li> <button type="button"
                                                                                                                       className="btn btn-primary btn-md btn-default next-step-sub"  onClick={() => this.lastStepSubmit(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount + 1,c.ID,questionData.name,pppp)}>Submit</button>
                                                                                                                   </li>
           
                                                                                                                 </ul>
           
                                                                                                         }
                                                                                                           })()} 
                                                                                                         </>
           
                                                                                                         ))}
                                                                                                     
                                                                                                     </div>
                                                                                                      
           
                                                                                                   
                                                                                               
                                                                                                         
                                                                                                       })()} 
                                                                                                       </>           
                                                                                                 </div>);
                                                                                                 
                                                                                               }) }
                                                                                     </>);
                                                                                  }else{
                                                                                      
                                                                                    return ( <>

                                                                                    {stepData.data.questions.sort((a, b) => a.CreatedAt > b.CreatedAt ? 1:-1).map((questionData,questionsKey, arr) => {
                                                                                      let pppp = nextStepcount++;
                                                                                      let kkkk = prvStepcount++;
                                                                                      let yyy = backnextStepcount++;
                                                                                      let oooo = backprvStepcount++;
                                                                                      return (<div className={stepKey === 0 &&questionsKey === 0 ? "tab-pane active show" : "tab-pane"} id={'tab-' + count++} key={questionsKey}>
                                                                                         <>
                                                                                             {(() => {
                                                                                               
                                                                                                   return <div><h2 className="mb-3 mt-3">{questionData.name}</h2>
                                                                                                   <span id={'output'+pppp}  style={{ color:'red',fontSize: '14px',float: 'left' }}></span>
                                                                                                   <br></br>
                                                                                                   <span style={{ color: "red" }}>{this.state.errors["nextRequired"]}</span>
                                                                                                   {questionData.step_fields.sort((a, b) => a.index > b.index ? 1 : -1).map((c, i) => (
         
                                                                                                       <>
                                                                                                       <div  id={'radio-container'+pppp}>
                                                                                                       {(() => {
                                                                                                         
                                                                                                         if(c.input_type === 'Text' || c.input_type === 'Number'){ 
                                                                                                           return<div className="col-md-6 mb-2 " key={i}>
                                                                                                                     <label htmlFor="inputEmail4">{c.name}
                                                                                                                         {(() => {
                                                                                                                             if (c.require == true) {
                                                                                                                                 return "*";
                                                                                                                             }
                                                                                                                         })()}
                                                                                                                     </label>
         
                                                                                                                     <input type={c.text_number} onBlur={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}  className="form-control quantityDropdown"
                                                                                                                         placeholder={c.placeholder} />
         
                                                                                                                 </div>
                                                                                                           }
         
                                                                                                           if (c.input_type === 'Dropdown') {                                                                                                   
                                                                                                             return<div className="col-md-6 mb-2 " key={i}>                                                                                                       
                                                                                                               <div className="form-group">
                                                                                                                     <label htmlFor="inputEmail4">{c.name}</label>
                                                                                                                     <select className="form-control quantityDropdown" name="" onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}
                                                                                                                       style={{background: 'none',color:'#6B7280',fontWeight: '500',fontSize: '11.602px'}}>
                                                                                                                         <option value="">Select {c.name}</option>
                                                                                                                           {c.options.sort((a, b) => a.CreatedAt < b.CreatedAt ? 1:-1).map((optionValue, optionKey) => {
                                                                                                                   
                                                                                                                                                 return ( 
                                                                                                                                                   <option value={optionValue.option_value}>{optionValue.option_value}</option>
                                                                                                                                                 );
                                                                                                                                       })                                                                                                                                      
                                                                                                                                       }                                                                                                               
                                                                                                                     </select>
                                                                                                                   </div>                                                                                                                                                                                                                                                                                                       
                                                                                                             </div>                                                                                                                                                                                                       
                                                                                                           }
                                                                                                             if (c.input_type === 'Radio Type Button') {      
         
                                                                                                               return <div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2"> 
                                                                                                                   <div className="row mb-2">
                                                                                                                       {c.options.map((optionValue, optionKey) => (    
                                                                                                                                                                                                                                                                                                                                                   
                                                                                                                                       <div className="col-md-3" id="step5chk">                                                    
                                                                                                                                         <input type="radio" id={optionValue.ID}  onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                         <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                                       </div>
                                                                                                                                       ))
                                                                                                                     }       
                                                                                                                     </div>                                                                                                                                                                                                                                                                                           
                                                                                                               </div>   
                                                                                                               </div>                                                                                                                                                                                                    
                                                                                                             }    
                                                                                                             
                                                                                                             if (c.input_type === 'Checkbox Type Button') {     
                                                                                                                                                                                                       
                                                                                                               return<div className="row mb-2 mt-2"  key={i}><div className="col-md-12 mb-2 mt-2" key={i}> 
                                                                                                               <div className="col-md-12" >
                                                                                                                         <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                                       </div>
                                                                                                                     <br></br>
                                                                                                                     <br></br>
                                                                                                               <div className="row mb-2">
                                                                                                                       {c.options.map((optionValue, optionKey) => (                                                                                                                                                                                                                               
                                                                                                                                       <div className="col-md-3" id="step5chk">
                                                                                                                                         <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID} value={optionValue.option_value}/>
                                                                                                                                         <label htmlFor={optionValue.ID}>{optionValue.option_value}</label>
                                                                                                                                       </div>
                                                                                                                                       ))
                                                                                                                     }       
                                                                                                                     </div>                                                                                                                                                                                                                                                                                           
                                                                                                               </div>  
                                                                                                               </div>                                                                                                                                                                                                     
                                                                                                             }
         
                                                                                                             if (c.input_type === 'Checkbox Type Box') {   
                                                                                                                                                                                                               
                                                                                                               return <div className="row mb-2 mt-2" key={i}> 
                                                                                                                       <div className="col-md-12" >
                                                                                                                         <h2 style={{ fontWeight: '500',fontSize: '15px' }}>Select all the options that apply:</h2> 
                                                                                                                       </div>
                                                                                                                     <br></br>
                                                                                                                     <br></br>
                                                                                                                       {c.options.map((optionValue, optionKey) => (     
                                                                                                                         <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                                     <input type="checkbox" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)}   name="hobbies" value={optionValue.option_value}/>
                                                                                                                                           <label htmlFor={optionValue.ID}>
                                                                                                                                             <div className="serBox">
                                                                                                                                               <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                                 <div className="col-md-12">
                                                                                                                                                 <center>                                                                                                                                           
                                                                                                                                                     <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                                     <img src={snowflake_1} alt="" style={{marginTop: '37px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                                   </center>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                               <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                                 id="test">
                                                                                                                                                 <div className="col-md-12">
                                                                                                                                                   <p> {optionValue.option_value}</p>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                             </div>
                                                                                                                                           </label>
                                                                                                                                       </div>
                                                                                                                                       ))
                                                                                                                     }       
                                                                                                                                                                                                                                                                                                                                                                                                             
                                                                                                               </div>                                                                                                                                                                                                       
                                                                                                             }
         
                                                                                                             if (c.input_type === 'Radio Type Box') {                                                                                                   
                                                                                                               return<div className="row mb-5 mt-5" key={i}> 
                                                                                                                 
                                                                                                                       {c.options.map((optionValue, optionKey) => (     
                                                                                                                         <div className="col-md-3" id="step3chk" style={{height: '200px',marginBottom:'10PX' }}>                                                                                                                                                                                                                          
                                                                                                                                     <input type="radio" id={optionValue.ID} onChange={(e) => this.questionDataChange(e,c.input_type,c.ID,questionData.name)} name={c.ID}value={optionValue.option_value}/>
                                                                                                                                           <label htmlFor={optionValue.ID}>
                                                                                                                                             <div className="serBox">
                                                                                                                                               <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}>
                                                                                                                                                 <div className="col-md-12">
                                                                                                                                                 <center>                                                                                                                                           
                                                                                                                                                     <img src={Vector1} alt="" style={{marginTop: '30px'}} />
                                                                                                                                                     <img src={snowflake_1} alt="" style={{marginTop: '33px',marginLeft:'-19px',position: 'absolute'}} />
                                                                                                                                                   </center>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                               <div className="row  row-eq-height" style={{height:'100px',marginRight: '0px', marginLeft: '0px'}}
                                                                                                                                                 id="test">
                                                                                                                                                 <div className="col-md-12">
                                                                                                                                                   <p> {optionValue.option_value}</p>
                                                                                                                                                 </div>
                                                                                                                                               </div>
                                                                                                                                             </div>
                                                                                                                                           </label>
                                                                                                                                       </div>
                                                                                                                                       ))
                                                                                                                     }       
                                                                                                                                                                                                                                                                                                                                                                                                             
                                                                                                               </div>                                                                                                                                                                                                       
                                                                                                             }
                                                                                                       })()}
                                                                                                       </div>
                                                                                                       <br></br>
                                                                                                       <br></br>
                                                                                                       <br></br>
                                                                                                       {(() => {
                                                                                                       
                                                                                                       if (questionsKey === 0  && stepKey === 0) {
                                                                                                         return <ul className="list-inline pull-left">
                                                                                                           {/* <li> <button type="button"
                                                                                                                     className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.props.backFirstStep()}>Back</button>
                                                                                                                 </li> */}
         
                                                                                                         <li> <button type="button"
                                                                                                             className="btn btn-primary btn-md btn-default next-step-sub" onClick={() => this.handleNextStep(c.input_type,'tab-' + pppp,'tab-' + kkkk ,activeTabeCount,c.ID,questionData.name,pppp)}>Next</button>
                                                                                                         </li>
                                                                                                       </ul>
         
                                                                                                       }else{
                                                                                                         return <ul className="list-inline pull-left">
                                                                                                               <li> <button type="button"
                                                                                                                     className="btn btn-primary btn-md btn-default-back prev-step-sub"  onClick={() => this.handlePrevStep(yyy, oooo,activeTabeCount)}>Back</button>
                                                                                                                 </li>
                                                                                                                 <li> <button type="button"
                                                                                                                     className="btn btn-primary btn-md btn-default next-step-sub"  onClick={() => this.lastStepSubmit(c.input_type,'tab-' +pppp,'tab-' + kkkk ,activeTabeCount + 1,c.ID,questionData.name,pppp)}>Submit</button>
                                                                                                                 </li>
         
                                                                                                               </ul>
         
                                                                                                       }
                                                                                                         })()} 
                                                                                                       </>
         
                                                                                                       ))}
                                                                                                   
                                                                                                   </div>
                                                                                                    
         
                                                                                                 
                                                                                             
                                                                                                       
                                                                                                     })()} 
                                                                                                     </>           
                                                                                               </div>);
                                                                                               
                                                                                             }) }
                                                                                   </>);
                                                                                  }
                                                                   })()}
                                                                            
                                                                            
                                                                           
                                                                      </>);
                                                                }) }
                                                             </>
                                                            }
                                                       </div>

                                                       {/* <p id="output" style={{ color:'red',fontSize: '14px',float: 'left' }}></p> */}

                                               </div>
                                           </div>

                                       </div>
                                   </div>

                               </section>
                           </div>
                    </div>
        </React.Fragment>
    );
}
}

export default connect(
    state => ({
        alerts: state.alerts.pending,
        menus: state.oss_menus,
        userdata: state.user.profileInfo.data,
        country: state.user.country,
        language: state.user.language,
       
    }),
    dispatch => ({

        getPRUsers: () => dispatch(getPRUsersAction()),

    })
)(ServiceOperatorChecklist);
